const generalMixin = {
  methods: {
    generalDetectWebP () {
      let res = false

      const el = (typeof document !== 'undefined') ? document.createElement('canvas') : false
      if (!!el && !!(el.getContext && el.getContext('2d'))) {
        res = el.toDataURL('image/webp').indexOf('data:image/webp') === 0
      }

      return res
    },

    generalNL2BR (str) {
      return String(str).replaceAll('\n', '<br>')
    },

    urlBuildParams (params) {
      const p = []
      const forcePage = !!params.forcePage
      delete params.forcePage

      Object.keys(params).forEach((i) => {
        if (i === 'page' && (parseInt(params[i]) === 1) && !forcePage) {
          // skip if 1st page
        } else {
          let val = params[i]
          if (Array.isArray(val)) {
            val = val.join(',')
          }
          if (!!val) {
            p.push(i + '=' + val)
          }
        }
      })

      return p.join(';')
    },
    urlGetParams (params) {
      const arrVals = ['exp_level', 'duration', 'type', 'workload', 'availability']
      const a = {}
      if (typeof params === 'string') {
        const p = params.split(';')
        for (const i in p) {
          const sub = p[i].split('=', 2)
          if (!!sub[0] && !!sub[1]) {
            let val = sub[1].split(',')
            if (arrVals.includes(sub[0])) {
              // we have array already
              // possible with multiple values
              // val = [sub[1]]
            } else {
              val = sub[1]
            }
            a[sub[0]] = val
          }
        }
      }

      return a
    },

    generalNiceDate (date) {
      const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000)
      const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })
      let result = ''

      let interval = seconds / 31536000
      if (interval > 1) {
        result = rtf.format(-Math.floor(interval), 'year')
      } else {
        interval = seconds / 2592000
        if (interval > 1) {
          result = rtf.format(-Math.floor(interval), 'month')
        } else {
          interval = seconds / 86400
          if (interval > 1) {
            result = rtf.format(-Math.floor(interval), 'day')
          } else {
            interval = seconds / 3600
            if (interval > 1) {
              result = rtf.format(-Math.floor(interval), 'hour')
            } else {
              interval = seconds / 60
              if (interval > 1) {
                result = rtf.format(-Math.floor(interval), 'minute')
              } else {
                result = rtf.format(-Math.floor(interval), 'second')
              }
            }
          }
        }
      }

      return result
    },

    generalShortDate (date) {
      return Intl.DateTimeFormat(this.$config.appLocale, this.$config.dayNoYearFormat).format(new Date(date))
    },

    generalParseText (text) {
      return text.replace(/(https?:\/\/[^\s]+)/g, function (url) {
        return '<a href="' + url + '" target="_blank" rel="ugc nofollow">' + url + '</a>'
      })
    }
  }
}

export default generalMixin
