import generalMixin from '~/mixins/generalMixin'

const userMixin = {
  mixins: [
    generalMixin
  ],

  methods: {
    userAvatarLetter (userName = '') {
      if (!userName) {
        userName = !!this.$auth.user ? this.$auth.user.name : 'Unnamed'
      }

      const result = []
      String(userName).split(' ').map(word => word.charAt(0) !== '' ? result.push(word.charAt(0)) : '')

      return String(result.join('')).slice(0, 2)
    },

    userAvatarImage (user = {}, addRandomQuery = true) {
      if (!user?.uuid) {
        user = this.$auth.user
      }

      let __image = user?.__image
      if (!!__image && this.generalDetectWebP()) {
        __image = __image.replace(/\.png/i, '.webp')
      }

      return (!!__image) ? this.$config.staticUrl + '/' + __image + (addRandomQuery ? '?' + Math.random() : '') : null
    }
  }
}

export default userMixin
