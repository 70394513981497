const inboxMixin = {
  methods: {
    async apiInboxGetContracts (page = 1, $axios = null) {
      const params = { page, include_message: true }
      $axios = $axios ?? this.$axios

      return await $axios.get('/contracts/list', { params }).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiInboxGetMessages (contractID, page = 1, $axios = null) {
      const params = { cuid: contractID, page }
      $axios = $axios ?? this.$axios

      return await $axios.get('/inbox/messages', { params }).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiInboxSendMessage (contractID, message, $axios = null) {
      const params = { cuid: contractID, message }
      $axios = $axios ?? this.$axios

      return await $axios.post('/inbox/send-message', params).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiInboxSendRead (contractID, $axios = null) {
      const params = { cuid: contractID }
      $axios = $axios ?? this.$axios

      return await $axios.post('/inbox/send-read', params).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiInboxCheckNewMessages ($axios = null) {
      $axios = $axios ?? this.$axios

      return await $axios.get('/inbox/check-new').then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiInboxCheckNotifications ($axios = null) {
      $axios = $axios ?? this.$axios

      return await $axios.get('/inbox/check-notifications').then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiInboxGetNotifications (page = 1, $axios = null) {
      const params = { page }
      $axios = $axios ?? this.$axios

      return await $axios.get('/inbox/notifications', { params }).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    },

    async apiInboxSendReadNotification (notifcationId, $axios = null) {
      const params = { id: notifcationId }
      $axios = $axios ?? this.$axios

      return await $axios.post('/inbox/send-notification-read', params).then((res) => {
        return res?.data
      }, (err) => {
        return err?.response?.data
      })
    }
  }
}

export default inboxMixin
