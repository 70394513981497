
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import generalMixin from '~/mixins/generalMixin'
import inboxMixin from '~/mixins/inbox/inboxMixin'
import userMixin from '~/mixins/users/userMixin'
import notificationsMixin from '~/mixins/notificationsMixin'

import logo from '~/components/logo'

export default {
  components: {
    logo
  },

  mixins: [
    generalMixin,
    inboxMixin,
    notificationsMixin,
    userMixin,
    validationMixin
  ],

  validations: {
    form: {
      searchText: { required }
    }
  },

  data () {
    return {
      loading: false,
      newMessages: 0,
      newNotificationsCount: 0,

      form: {
        searchText: null
      }
    }
  },

  computed: {
    formInvalid () {
      return this.$v.form.$invalid
    }
  },

  mounted () {
    this.checkNewMessages()
    this.checkNotifications()
  },

  methods: {
    menuShow (menuId) {
      if (!!this.$el.querySelector('#' + menuId)) {
        for (const entry of this.$el.querySelectorAll('.menu-dd').entries()) {
          entry[1].classList.remove('menu-dd-show')
        }
        this.$el.querySelector('#' + menuId).classList.add('menu-dd-show')
      }
    },
    menuHide (e) {
      e.target.classList.remove('menu-dd-show')
    },

    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    async logOut (e) {
      e.preventDefault()

      this.loading = true

      await this.$auth.logout()
      this.$router.push(this.localePath({ name: 'index' }))

      this.loading = false
    },

    onSearchJobs () {
      this.$router.push(this.localePath({ name: 'jobs-filter', params: { filter: this.urlBuildParams({ search: this.form.searchText }) } }))
    },

    async checkNewMessages () {
      if (!!this.$auth?.user?.uuid) {
        try {
          const res = await this.apiInboxCheckNewMessages()
          if (!res?.success) {
            // do nothing
          } else {
            if (res.new_messages > this.newMessages) {
              this.notificationsNewMessages('message', res.new_messages)
            }
            this.newMessages = res.new_messages ?? 0
          }
        } catch (e) {
          // do nothing
        } finally {
          setTimeout(this.checkNewMessages, 30000)
        }
      }
    },

    async checkNotifications () {
      if (!!this.$auth?.user?.uuid) {
        try {
          const res = await this.apiInboxCheckNotifications()
          if (!res?.success) {
            // do nothing
          } else {
            if (res.notifications.total > this.newNotificationsCount) {
              this.notificationsProcessNotifications(res.notifications)
            }
            this.newNotificationsCount = res.notifications?.total ?? 0
          }
        } catch (e) {
          // do nothing
        } finally {
          setTimeout(this.checkNotifications, 60000)
        }
      }
    }
  }
}
