
import footerNav from '~/components/navigation/footer'
import headerNav from '~/components/navigation/header'

export default {
  components: {
    footerNav,
    headerNav
  }
}
