export default function (req, res, next) {
  const paths = [
    '/account',
    '/dashboard',
    '/inbox',
    '/stats',
    '/workload'
  ]

  paths.forEach((row) => {
    if (req.originalUrl.startsWith(row)) {
      // trigger the "traditional SPA mode"
      res.spa = true
      res.ssr = false
    }
  })

  next()
}
