const notificationsMixin = {
  methods: {
    notificationsNewMessages (type, messagesCount) {
      if (this.notificationsMustDisplay('messages')) {
        this.$bvToast.toast(this.$t('toast.notifications.messages', { count: messagesCount }), {
          title: this.$t('toast.header.info'),
          variant: 'info',
          solid: true
        })
        this.notificationsPlaySound(type)
      }
    },

    notificationsProcessNotifications (notifications) {
      if (Array.isArray(notifications.rows) && notifications.rows.length > 0) {
        notifications.rows.forEach((item) => {
          if (this.notificationsMustDisplay(item.type)) {
            switch (item.type) {
              case 'project_invite':
                this.$bvToast.toast(this.$t('toast.notifications.types.' + item.type, { juid: item.raw_data.juid }), {
                  title: this.$t('toast.header.info'),
                  variant: 'info',
                  solid: true
                })
                this.notificationsPlaySound(item.type)
                break
              case 'outbid':
                this.$bvToast.toast(this.$t('toast.notifications.types.' + item.type, { juid: item.raw_data.juid }), {
                  title: this.$t('toast.header.info'),
                  variant: 'info',
                  solid: true
                })
                this.notificationsPlaySound(item.type)
                break
              case 'new_bid':
                this.$bvToast.toast(this.$t('toast.notifications.types.' + item.type, { juid: item.raw_data.juid }), {
                  title: this.$t('toast.header.info'),
                  variant: 'info',
                  solid: true
                })
                this.notificationsPlaySound(item.type)
                break
              default:
                break
            }
          }
        })
      }
    },

    notificationsPlaySound (type = 'in') {
      if (this.$auth?.user?.notification_settings?.sound === 'play') {
        if (!['in', 'out', 'message'].includes(type)) {
          type = 'in'
        }

        const audioElement = new Audio(this.$config.baseUrl + '/sounds/notification-' + type + '.mp3')
        audioElement.addEventListener('canplaythrough', () => {
          audioElement.play()
        })
      }
    },

    notificationsMustDisplay (type = 'messages') {
      let result = true
      if (['messages', 'project_invite'].includes(type)) {
        if (!['all', 'important'].includes(this.$auth?.user?.notification_settings?.browser)) {
          result = false
        }
      }

      return result
    }
  }
}

export default notificationsMixin
