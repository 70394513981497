
export default {
  props: {
    size: {
      type: String,
      default: '36',
      required: false
    }
  }
}
